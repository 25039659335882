//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logoWhatsapp from '$resources/images/whatsapp.png';
import logoCompany from '$resources/images/logo-company.png';

export default {
  name: 'LandingLayout',
  data: () => ({
    logoWhatsapp,
    logoCompany
  }),
  methods: {
    openWhatsapp() {
      window.open('https://wa.me/+34652773504?text=INFO', '_blank');
    }
  }
};
